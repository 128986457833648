$negro: #071108;
$blanco: #e3e7d3;
$naranja: #f6511d;
$celeste: #00a6ed;
$amarillo: #ffb400;
$gris: #364652;

body{
    font-family: 'Roboto', sans-serif;
}
