@import '../../variables.scss';

.menu{
    position: relative;
    width: 5vw;
    height: 100vh;
    background: $negro;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    z-index: 300;
    @media only screen and (max-width: 800px) {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        max-width: 100%;
        height: 10vh;
        flex-direction: row;
        justify-content: center;
    }
    .titulo{
        width: 5vw;
        height: 5vw;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $negro;
        color:$blanco;
        @media only screen and (max-width: 800px) {
            display: none;
        }
        h1{
            margin: 0;
            padding: 0;
        }
        img{
            width: 80%;
            margin: auto;
            padding: 0;
        }
    }

    a{
        width: 5vw;
        height: 5vw;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $negro;
        color:$blanco;
        text-decoration: none;
        font-size: 2vmin;
        transition: all .4s ease-out,font-size .2s ease-out;
        @media only screen and (max-width: 800px) {
            width: 10vh;
            height: 10vh;
            font-size: 4vmin;
        }
        &.active{
            background: $amarillo;
            color:$celeste;
            font-size: 3vmin;
            @media only screen and (max-width: 800px) {
                font-size: 6vmin;
            }
        }
    }   
}
