@import '../../variables.scss';

.modal{
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    z-index: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(10,10,10,.6);
    @media only screen and (max-width: 800px) {
        position: fixed;
    }
    .carta{
        width: 60vw;
        height: 50vh;
        background: $blanco;
        color: $negro;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        @media only screen and (max-width: 800px) {
            width: 90vw;
            height: 60vh;
        }
        .contenido{
            width: 50vw;
            height: 35vh;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            font-size: 1.8vmin;
            @media only screen and (max-width: 800px) {
                font-size: 3vmin;
                width: 80vw;
                height: 40vh;
            }
            h2{
                font-size: 3vmin;
                color: $celeste;
                @media only screen and (max-width: 800px) {
                    font-size: 6vmin;
                }
            }
            a{
                padding: 1vmin 2vmin;
                margin: .5vmin;
                background: $celeste;
                color: $blanco;
                text-decoration: none;
                @media only screen and (max-width: 800px) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 80vw;
                    height: 5vh;
                    margin: 1vmin;
                    font-size: 3vmin;
                }
                i{
                    font-size: 4vmin;
                    margin-right: 1vmin;
                }
            }
        }
        .botonera{
            width: 50vw;
            height: 10vh;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            @media only screen and (max-width: 800px) {
                width: 80vw;
                height: 8vh;
            }
            button{
                width: 5vh;
                height: 5vh;
                background: transparent;
                border: none;
                color: $naranja;
                font-size: 4vmin;
                @media only screen and (max-width: 800px) {
                    width: 8vw;
                    height: 8vh;
                    font-size: 6vmin;
                }
                &:hover{
                    color: lighten($color: $naranja, $amount: 30);
                    cursor: pointer;
                }
            }
        }
    }
    &.cerrado{
        display: none;
    }
}