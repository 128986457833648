@import '../../variables.scss';


.foto{
    position: relative;
    width: 30vw;
    height: 100vh;
    overflow: hidden;
    @media only screen and (max-width: 800px) {
        width: 100vw;
        height: 90vh;
        max-width: 100%;
    }
    img{
        height: 100%;
        width: auto;
        object-fit: cover;
        filter: contrast(85%) brightness(110%) saturate(75%) sepia(22%);
        &::before{
            content: "";
            display: block;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            position: absolute;
            pointer-events: none;
            mix-blend-mode: soft-light;
            opacity: 0.5;
            background: rgba(173, 205, 239, 1);
        }
        @media only screen and (max-width: 800px) {
            width: 100%;
        }
    }
}
.redes{
    position: absolute;
    bottom: 10vh;
    left: 0;
    width: 30vw;
    height: 10vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 800px) {
        width: 100vw;
        max-width: 100%;
    }
    a{
        text-decoration: none;
        color:$naranja;
        font-size: 3vmin;
        margin: 2vmin;
        transition: all .2s ease-in;
        @media only screen and (max-width: 800px) {
            font-size: 8vmin;
            margin: 4vmin;
        }
        &:hover{
            font-size: 4vmin; 
        }
        @media only screen and (max-width: 800px) {
            font-size: 8vmin;
        }
    }
}
.titulo{
    position: absolute;
    bottom: 20vh;
    left: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30vw;
    height: 15vh;
    padding: 5vmin;
    background: $naranja;
    color: $blanco;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    @media only screen and (max-width: 800px) {
        width: 100vw;
        max-width: 100%;
    }
    h1,p{
        margin: 0;
        padding: 0;
    }
    h1{
        font-size: 3vmin;
        @media only screen and (max-width: 800px) {
            font-size: 6vmin;
        }
    }
    p{
        font-size: 1.8vmin;
        border-top: solid 1px $blanco;
        border-bottom: solid 1px $blanco;
        margin-top: 2vmin;
        padding: 1vmin 0vmin;
        @media only screen and (max-width: 800px) {
            font-size: 3vmin;
        }
    }
}
.contenido{
    position: relative;
    width: 60vw;
    height: 100vh;
    overflow: hidden;
    color: $blanco;
    @media only screen and (max-width: 800px) {
        width: 100vw;
        max-width: 100%;
        height: auto;
    }
    .carta{
        margin: 3vmin;
        margin-bottom: 5vmin;
        padding-bottom: 3vmin;
        border-bottom: solid 1px $gris;
        h1{
            color: $naranja;
            font-size: 6vmin;
            margin: 0;
            padding: 1vmin 0vmin;
        }
        h3{
            margin: 0;
            padding: 0;
            color:$celeste;
            font-size: 2.5vmin;
            @media only screen and (max-width: 800px) {
                font-size: 5vmin;
            }
        }
        ul{
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 1.8vmin;
            @media only screen and (max-width: 800px) {
                font-size: 4vmin;
            }
            li{
                margin-bottom: 1.5vmin;
                
                cursor: pointer;
                i{
                    color:$amarillo;
                    margin-right: .5vmin;
                }
                span{
                    color: $celeste;
                }
            }
        }
    }
}

.formulario{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40vw;
    height: 40vh;
    @media only screen and (max-width: 800px) {
        width: 90vw;
        height: 50vh;
        margin: auto;
    }
    input[type="text"],input[type="email"]{
        width: 99%;
        height: 4vh;
        margin-bottom: 2vmin;
    }
    textarea{
        width: 99%;
        height: 10vh;
        resize: none;
    }
    h4{
        align-self: flex-start;
        margin: 0;
        margin-top: 1vmin;
    }
    button{
        width: 100%;
        height: 5vh;
        background: $naranja;
        color: $blanco;
        border:none;
        margin: 0;
        margin-top: 1vmin;
        &:hover{
            background: lighten($color: $naranja, $amount: 10);
        }
        &:active{
            background: $celeste;
        }
    }
}
.alertaExitoso{
    color:green;
}
.alertaFallo{
    color: red;
}