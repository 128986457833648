@import './variables.scss';
.base{
    display: flex;
    flex-direction: row;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    overflow: hidden;
    background: $negro;
    @media only screen and (max-width: 800px) {
        flex-direction: column;
        height: auto;
        margin-bottom: 10vh;
    }
}

